<template>
    <main class="main-page" id="">
        <section class="page-section q-pa-md" >
            <div class="container-fluid">
                <div class="grid ">
                    <div  class="col comp-grid" >
                        <div class=" text-lg font-bold text-primary" >
                            Home
                        </div>
                        <hr class="my-3" />
                    </div>
                    <div  class="col-12 comp-grid" >
                    </div>
                </div>
            </div>
        </section>
        <section class="page-section mb-3" >
            <div class="container-fluid">
                <div class="grid ">
                    <div  class="col-12 lg:col-3 md:col-6 comp-grid" >
                        <record-count api-path="components_data/getcount_ultimoconsumo" max="" v-slot="record">
                        <router-link  :to="`/data`">
                            <div class="card " >
                                <div class="font-medium text-lg mb-3">Ultimo Consumo</div>
                                <div class="flex align-items-center justify-content-between mb-3">
                                    <div>
                                        <div class="font-bold text-4xl" v-if="!record.loading">{{ record.num }}</div>
                                        <ProgressSpinner v-else style="width:20px;height:20px" />
                                    </div>
                                    <div style="width:auto;">
                                        <Avatar icon="pi pi-stopwatch" size="xlarge" class="" />
                                        </div>
                                    </div>
                                    <div class="text-500 text-sm">Total Data</div>
                                </div>
                            </router-link>
                            </record-count>
                        </div>
                        <div  class="col-12 lg:col-3 md:col-6 comp-grid" >
                            <record-count api-path="components_data/getcount_mesactual" max="" v-slot="record">
                            <router-link  :to="`/devices`">
                                <div class="card " >
                                    <div class="font-medium text-lg mb-3">Mes actual</div>
                                    <div class="flex align-items-center justify-content-between mb-3">
                                        <div>
                                            <div class="font-bold text-4xl" v-if="!record.loading">{{ record.num }}</div>
                                            <ProgressSpinner v-else style="width:20px;height:20px" />
                                        </div>
                                        <div style="width:auto;">
                                            <Avatar icon="pi pi-calendar" size="xlarge" class="" />
                                            </div>
                                        </div>
                                        <div class="text-500 text-sm">Total Devices</div>
                                    </div>
                                </router-link>
                                </record-count>
                            </div>
                            <div  class="col-12 lg:col-3 md:col-6 comp-grid" >
                                <record-count api-path="components_data/getcount_esteao" max="" v-slot="record">
                                <router-link  :to="`/users`">
                                    <div class="card " >
                                        <div class="font-medium text-lg mb-3">Este año</div>
                                        <div class="flex align-items-center justify-content-between mb-3">
                                            <div>
                                                <div class="font-bold text-4xl" v-if="!record.loading">{{ record.num }}</div>
                                                <ProgressSpinner v-else style="width:20px;height:20px" />
                                            </div>
                                            <div style="width:auto;">
                                                <Avatar icon="pi pi-history" size="xlarge" class="" />
                                                </div>
                                            </div>
                                            <div class="text-500 text-sm">Total Users</div>
                                        </div>
                                    </router-link>
                                    </record-count>
                                </div>
                                <div  class="col-12 lg:col-3 md:col-6 comp-grid" >
                                    <record-count api-path="components_data/getcount_promediodiario" max="" v-slot="record">
                                    <router-link  :to="`/users_type`">
                                        <div class="card " >
                                            <div class="font-medium text-lg mb-3">Promedio diario</div>
                                            <div class="flex align-items-center justify-content-between mb-3">
                                                <div>
                                                    <div class="font-bold text-4xl" v-if="!record.loading">{{ record.num }}</div>
                                                    <ProgressSpinner v-else style="width:20px;height:20px" />
                                                </div>
                                                <div style="width:auto;">
                                                    <Avatar icon="pi pi-chart-bar" size="xlarge" class="" />
                                                    </div>
                                                </div>
                                                <div class="text-500 text-sm">Total Users Type</div>
                                            </div>
                                        </router-link>
                                        </record-count>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section class="page-section " >
                            <div class="container-fluid">
                                <div class="grid ">
                                    <div  class="col-12 md:col-6 comp-grid" >
                                        <div :class="{ 'card ': !isSubPage }" class="">
                                            <div class="q-pa-md">
                                                <div class="font-bold text-lg">Consumo este mes</div>
                                                <div class="text-500">Registro diario</div>
                                                <hr />
                                                <div class="row q-col-gutter-sm">
                                                    <div class="col">
                                                        <api-data-source   api-path="components_data/linechart_consumoestemes" >
                                                            <template v-slot="req">
                                                                <div style="position:relative;text-align:center">
                                                                    <div class="text-center p-3" v-if="req.loading">
                                                                        <ProgressSpinner style="width:50px;height:50px" />
                                                                    </div>
                                                                    <vue-chart :chart-data="req.response" chart-type="line" ></vue-chart>
                                                                </div>
                                                            </template>
                                                        </api-data-source>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div  class="col-12 md:col-6 comp-grid" >
                                        <div :class="{ 'card ': !isSubPage }" class="">
                                            <div class="q-pa-md">
                                                <div class="font-bold text-lg">Consumo Anual</div>
                                                <div class="text-500">Registro mensual</div>
                                                <hr />
                                                <div class="row q-col-gutter-sm">
                                                    <div class="col">
                                                        <api-data-source   api-path="components_data/barchart_consumoanual" >
                                                            <template v-slot="req">
                                                                <div style="position:relative;text-align:center">
                                                                    <div class="text-center p-3" v-if="req.loading">
                                                                        <ProgressSpinner style="width:50px;height:50px" />
                                                                    </div>
                                                                    <vue-chart :chart-data="req.response" chart-type="bar" ></vue-chart>
                                                                </div>
                                                            </template>
                                                        </api-data-source>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </main>
                
</template>
<script setup>
	import {  ref } from 'vue';
	
	
	import { useApp } from 'src/composables/app.js';
	
	
	import VueChart from 'src/components/VueCharts.vue';
	
	const props = defineProps({
		pageName: {
			type: String,
			default: 'home',
		},
		routeName: {
			type: String,
			default: 'home',
		},
		isSubPage: {
			type : Boolean,
			default : false,
		},
	});
	const app = useApp();
	
	const pageReady = ref(true);
	
</script>
<style scoped>
</style>
